import React from 'react'
import { graphql } from 'gatsby'
import { Head } from 'src/components/Layout'
import { Container, ColumnGrid } from 'src/components/Layout'
import { Card, FeaturedContainer, PostList } from 'src/components/Posts'
import {
  BannerContainer,
  BannerContent,
  BannerHeading,
} from 'src/templates/category'

export default function CaseStudies({ data }): JSX.Element {
  const posts =
    Array.isArray(data?.allWpCaseStudy?.nodes) && data?.allWpCaseStudy?.nodes

  const [first, ...rest] = posts

  return (
    <>
      <Head
        meta={{
          title: '',
          description: 'A collection of case studies from Colloqui Interactive',
          media: null,
        }}
        page={{
          __typename: 'WpCaseStudy',
          title: 'Case Studies',
          uri: '/case-studies/',
        }}
      />
      <BannerContainer>
        <ColumnGrid>
          <BannerContent>
            <BannerHeading>Case Studies</BannerHeading>
          </BannerContent>
        </ColumnGrid>
      </BannerContainer>
      <Container
        background={{
          kind: 'colour',
          colour: { desktop: 'var(--beige)', mobile: 'var(--beige)' },
          divider: 'flat',
        }}
        zIndex={2}
        top={true}
        padding={{
          desktop: [
            { direction: ['top'], value: 80, unit: 'px' },
            { direction: ['bottom'], value: 150, unit: 'px' },
          ],
          mobile: [
            { direction: ['top'], value: 50, unit: 'px' },
            { direction: ['bottom'], value: 150, unit: 'px' },
          ],
        }}
      >
        <ColumnGrid>
          {posts.length > 0 ? (
            <>
              <FeaturedContainer>
                {first && <Card data={first} size="large" />}
              </FeaturedContainer>
              <PostList
                css={`
                  padding: 0;
                `}
              >
                {rest.map(post => (
                  <li
                    key={post.id}
                    css={`
                      min-width: 0;
                    `}
                  >
                    <Card data={post} size="small" />
                  </li>
                ))}
              </PostList>
            </>
          ) : (
            <Message>No posts in this category.</Message>
          )}
        </ColumnGrid>
      </Container>
    </>
  )
}

export const pageQuery = graphql`
  query AllWpCaseStudy {
    allWpCaseStudy(sort: { order: DESC, fields: date }) {
      nodes {
        ...CaseStudySummaryFields
      }

      totalCount
    }
  }
`
