import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Head } from 'src/components/Layout'
import { Container, ColumnGrid } from 'src/components/Layout'
import { Card, FeaturedContainer, PostList } from 'src/components/Posts'
import { h1, h4 } from 'src/lib/typography/preset'

export const BannerContainer = styled.section`
  background-color: var(--purple900);
  padding-top: 90px;
`

export const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 125px;

  grid-column: 1 / -1;

  @media (min-width: 800px) {
    grid-column: 2 / 12;
    min-height: 400px;
  }
`

export const BannerHeading = styled.h2`
  ${h1}

  color: var(--white);
  margin: 0;
  text-align: center;
`

export const BannerSubheading = styled.h4`
  ${h4}

  color: var(--white);
  margin: 0;
  text-align: center;
`

export const Featured = styled.div`
  grid-column: 1 / -1;
  margin: 40px 0;
`

export const List = styled.ul`
  grid-column: 1 / -1;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;

  list-style: none;
  margin: 40px 0;
  padding: 0;

  /* @media (min-width: 800px) {
  } */
`

export const Message = styled.span`
  grid-column: 1 / -1;

  ${h4}
`

export default function Category({ data }): JSX.Element {
  const posts =
    Array.isArray(data?.wpCategory?.posts?.nodes) &&
    data?.wpCategory?.posts?.nodes

  const [first, ...rest] = posts

  return (
    <>
      <Head
        meta={{
          title: data?.wpCategory?.name || '',
          description: data?.wpCategory?.description || '',
          media: null,
        }}
        page={{
          __typename: 'WpCategory',
          title: data?.wpCategory?.name || '',
          uri: data?.wpCategory?.uri || '',
        }}
      />
      <BannerContainer>
        <ColumnGrid>
          <BannerContent>
            <BannerHeading>{data?.wpCategory?.name}</BannerHeading>
          </BannerContent>
        </ColumnGrid>
      </BannerContainer>
      <Container
        background={{
          kind: 'colour',
          colour: { desktop: 'var(--beige)', mobile: 'var(--beige)' },
          divider: 'flat',
        }}
        zIndex={2}
        top={true}
        padding={{
          desktop: [
            { direction: ['top'], value: 80, unit: 'px' },
            { direction: ['bottom'], value: 150, unit: 'px' },
          ],
          mobile: [
            { direction: ['top'], value: 50, unit: 'px' },
            { direction: ['bottom'], value: 150, unit: 'px' },
          ],
        }}
      >
        <ColumnGrid>
          {posts.length > 0 ? (
            <>
              <FeaturedContainer>
                {first && <Card data={first} size="large" />}
              </FeaturedContainer>
              <PostList
                css={`
                  padding: 0;
                `}
              >
                {rest.map(post => (
                  <li
                    key={post.id}
                    css={`
                      min-width: 0;
                    `}
                  >
                    <Card data={post} size="small" />
                  </li>
                ))}
              </PostList>
            </>
          ) : (
            <Message>No posts in this category.</Message>
          )}
        </ColumnGrid>
      </Container>
    </>
  )
}

export const pageQuery = graphql`
  query WpCategory($id: String!) {
    wpCategory(id: { eq: $id }) {
      id
      name
      uri
      posts {
        nodes {
          __typename
          ...PostSummaryFields
        }
      }
    }
  }
`
